@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  padding: 0;
}
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

.font-navbar {
  font-family: "Press Start 2P", cursive;
}
.font-default {
  font-family: "Roboto", sans-serif;
}

.heading {
  -webkit-text-stroke: 0.5px white;
}
.heading-about-ideas {
  -webkit-text-stroke: 0.3px #1fde00;
}
.heading-about-reality {
  -webkit-text-stroke: 0.3px #00a3ff;
}
